@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?pktxr2');
  src:  url('fonts/icomoon.eot?pktxr2#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?pktxr2') format('truetype'),
    url('fonts/icomoon.woff?pktxr2') format('woff'),
    url('fonts/icomoon.svg?pktxr2#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cupra:before {
  content: "\e965";
}
.icon-electro:before {
  content: "\e900";
}
.icon-high:before {
  content: "\e901";
}
.icon-low:before {
  content: "\e902";
}
.icon-medium:before {
  content: "\e903";
}
.icon-screen-rotate-to-portrait:before {
  content: "\e904";
}
.icon-screen-rotate:before {
  content: "\e905";
}
.icon-berline:before {
  content: "\e906";
}
.icon-break:before {
  content: "\e907";
}
.icon-compact:before {
  content: "\e908";
}
.icon-convertible:before {
  content: "\e909";
}
.icon-monovolume:before {
  content: "\e90a";
}
.icon-suv:before {
  content: "\e90b";
}
.icon-van:before {
  content: "\e90c";
}
.icon-coupe:before {
  content: "\e90d";
}
.icon-acura:before {
  content: "\e90e";
}
.icon-alfa-romeo-alt:before {
  content: "\e90f";
}
.icon-alfa-romeo:before {
  content: "\e910";
}
.icon-am-general:before {
  content: "\e911";
}
.icon-aston-martin-alt:before {
  content: "\e912";
}
.icon-aston-martin:before {
  content: "\e913";
}
.icon-audi:before {
  content: "\e914";
}
.icon-bentley:before {
  content: "\e915";
  color: #4d4d4d;
}
.icon-bugatti:before {
  content: "\e916";
}
.icon-buick:before {
  content: "\e917";
}
.icon-cadillac:before {
  content: "\e918";
}
.icon-chevrolet:before {
  content: "\e919";
}
.icon-chrysler:before {
  content: "\e91a";
}
.icon-citroen:before {
  content: "\e91b";
}
.icon-daewoo:before {
  content: "\e91c";
}
.icon-dodge:before {
  content: "\e91d";
}
.icon-eagle:before {
  content: "\e91e";
}
.icon-ferrari:before {
  content: "\e91f";
}
.icon-fiat-alt:before {
  content: "\e920";
}
.icon-fiat:before {
  content: "\e921";
}
.icon-fisker:before {
  content: "\e922";
}
.icon-ford:before {
  content: "\e923";
}
.icon-genesis:before {
  content: "\e924";
}
.icon-geo:before {
  content: "\e925";
}
.icon-gmc:before {
  content: "\e926";
}
.icon-honda:before {
  content: "\e927";
}
.icon-hummer:before {
  content: "\e928";
}
.icon-hyundai:before {
  content: "\e929";
}
.icon-infiniti:before {
  content: "\e92a";
}
.icon-isuzu:before {
  content: "\e92b";
}
.icon-jaguar-alt:before {
  content: "\e92c";
}
.icon-jaguar:before {
  content: "\e92d";
}
.icon-jeep-alt:before {
  content: "\e92e";
}
.icon-jeep:before {
  content: "\e92f";
}
.icon-kia:before {
  content: "\e930";
}
.icon-lamborghini-alt:before {
  content: "\e931";
}
.icon-lamborghini:before {
  content: "\e932";
}
.icon-land-rover:before {
  content: "\e933";
}
.icon-lexus:before {
  content: "\e934";
}
.icon-lincoln:before {
  content: "\e935";
}
.icon-lotus-alt:before {
  content: "\e936";
}
.icon-lotus:before {
  content: "\e937";
}
.icon-maserati:before {
  content: "\e938";
}
.icon-maybach:before {
  content: "\e939";
}
.icon-mazda-alt:before {
  content: "\e93a";
}
.icon-mazda:before {
  content: "\e93b";
}
.icon-mclaren-alt:before {
  content: "\e93c";
}
.icon-mclaren:before {
  content: "\e93d";
}
.icon-mercedes-benz-alt:before {
  content: "\e93e";
}
.icon-mercedes-benz:before {
  content: "\e93f";
}
.icon-mercury:before {
  content: "\e940";
}
.icon-mini-alt:before {
  content: "\e941";
}
.icon-mini:before {
  content: "\e942";
}
.icon-mitsubishi:before {
  content: "\e943";
}
.icon-nissan:before {
  content: "\e944";
}
.icon-oldsmobile:before {
  content: "\e945";
}
.icon-opel:before {
  content: "\e946";
}
.icon-panoz:before {
  content: "\e947";
}
.icon-peugeot-alt:before {
  content: "\e948";
}
.icon-peugeot:before {
  content: "\e949";
}
.icon-plymouth:before {
  content: "\e94a";
}
.icon-pontiac:before {
  content: "\e94b";
}
.icon-porsche:before {
  content: "\e94c";
}
.icon-ram-alt:before {
  content: "\e94d";
}
.icon-ram:before {
  content: "\e94e";
}
.icon-renault-alt:before {
  content: "\e94f";
}
.icon-renault:before {
  content: "\e950";
}
.icon-rolls-royce:before {
  content: "\e951";
}
.icon-saab-alt:before {
  content: "\e952";
}
.icon-saab:before {
  content: "\e953";
}
.icon-saturn:before {
  content: "\e954";
}
.icon-scion:before {
  content: "\e955";
}
.icon-seat:before {
  content: "\e956";
}
.icon-skoda:before, .icon-škoda:before {
  content: "\e957";
}
.icon-smart:before {
  content: "\e958";
}
.icon-spyker-alt:before {
  content: "\e959";
}
.icon-spyker:before {
  content: "\e95a";
}
.icon-subaru-alt:before {
  content: "\e95b";
}
.icon-subaru:before {
  content: "\e95c";
}
.icon-suzuki:before {
  content: "\e95d";
}
.icon-tesla:before {
  content: "\e95e";
}
.icon-toyota:before {
  content: "\e95f";
}
.icon-volvo-alt:before {
  content: "\e960";
}
.icon-volvo:before {
  content: "\e961";
}
.icon-volkswagen:before, .icon-vw:before {
  content: "\e962";
}
.icon-volkswagen-commercial:before {
  content: "\e963";
}
.icon-bmw:before {
  content: "\e964";
}
